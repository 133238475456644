import React, { useState, useEffect } from "react";

/* import { withRouter } from 'react-router-dom'; */
import './../App.css';
import styled, { keyframes } from 'styled-components';


const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};

class Faq extends React.Component {



  render() {
    return (
     <div>
        <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
     FAQ
      </HeroText>
    </section>

        <section class="FAQ">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div id="main" class="gfx-main">
                            <div class="container">
                                <div class="gfx-faq accordion" id="faq">
                                    <div class="card">
                                        <div class="card-header" id="faqhead1">
                                            <a href="#" class="btn btn-header-link" data-toggle="collapse"
                                                data-target="#faq1" aria-expanded="true" aria-controls="faq1">
                                                How to mint nfts on the nft marketplace?
                                            </a>
                                        </div>

                                        <div id="faq1" class="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                                            <div class="card-body">
                                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                                praesentium voluptatum deleniti atque corrupti
                                                quos dolores et quas molestias excepturi sint occaecati.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="faqhead2">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                data-target="#faq2" aria-expanded="true" aria-controls="faq2">
                                                How can I get my tarnished warrior?
                                            </a>
                                        </div>

                                        <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                                            <div class="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                richardson ad squid.
                                                3 wolf
                                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                nesciunt laborum
                                                eiusmod.
                                                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                coffee nulla
                                                assumenda
                                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                cred nesciunt
                                                sapiente ea
                                                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                farm-to-table, raw
                                                denim
                                                aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="faqhead3">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                data-target="#faq3" aria-expanded="true" aria-controls="faq3">
                                                What is the minting price of warriors?
                                            </a>
                                        </div>

                                        <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                            <div class="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                richardson ad squid.
                                                3 wolf
                                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                nesciunt laborum
                                                eiusmod.
                                                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                coffee nulla
                                                assumenda
                                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                cred nesciunt
                                                sapiente ea
                                                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                farm-to-table, raw
                                                denim
                                                aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="faqhead3">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                data-target="#faq4" aria-expanded="true" aria-controls="faq3">
                                                What is tarnished warriors?
                                            </a>
                                        </div>

                                        <div id="faq4" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                            <div class="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                richardson ad squid.
                                                3 wolf
                                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                nesciunt laborum
                                                eiusmod.
                                                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                coffee nulla
                                                assumenda
                                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                cred nesciunt
                                                sapiente ea
                                                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                farm-to-table, raw
                                                denim
                                                aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="faqhead3">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                data-target="#faq5" aria-expanded="true" aria-controls="faq3">
                                                What is limit fro one mint?
                                            </a>
                                        </div>

                                        <div id="faq5" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                            <div class="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                richardson ad squid.
                                                3 wolf
                                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                nesciunt laborum
                                                eiusmod.
                                                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                coffee nulla
                                                assumenda
                                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                cred nesciunt
                                                sapiente ea
                                                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                farm-to-table, raw
                                                denim
                                                aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="faqhead3">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                data-target="#faq6" aria-expanded="true" aria-controls="faq3">
                                                Are discounts included?
                                            </a>
                                        </div>

                                        <div id="faq6" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                            <div class="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                richardson ad squid.
                                                3 wolf
                                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                nesciunt laborum
                                                eiusmod.
                                                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                coffee nulla
                                                assumenda
                                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                cred nesciunt
                                                sapiente ea
                                                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                farm-to-table, raw
                                                denim
                                                aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="faqhead3">
                                            <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                data-target="#faq7" aria-expanded="true" aria-controls="faq3">
                                                Do you need a smart contract to mint?
                                            </a>
                                        </div>

                                        <div id="faq7" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
                                            <div class="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                richardson ad squid.
                                                3 wolf
                                                moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                nesciunt laborum
                                                eiusmod.
                                                Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                coffee nulla
                                                assumenda
                                                shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                cred nesciunt
                                                sapiente ea
                                                proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                farm-to-table, raw
                                                denim
                                                aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		</div>
    );
  }
};

export default Faq;