import React, { useState, useEffect } from "react";
/* import { withRouter } from 'react-router-dom'; */
// import './../App.css';
import styled, { keyframes } from 'styled-components';


import blog1 from './../images/blog-01.png';
import blog2 from './../images/blog-02.png';
import blog3 from './../images/blog-03.png';
import blog4 from './../images/blog-04.png';
import blogicon1 from './../images/blog-icon-01.svg';
import blogicon2 from './../images/blog-icon-02.svg';
import Social1 from './../images/footer-social-icon-01.svg';
import Social2 from './../images/footer-social-icon-02.svg';
import Social3 from './../images/footer-social-icon-03.svg';
import Social4 from './../images/footer-social-icon-04.svg';
import gallery1 from './../images/sidebar-gallery-01.png';
import gallery2 from './../images/sidebar-gallery-02.png';
import gallery3 from './../images/sidebar-gallery-03.png';
import gallery4 from './../images/sidebar-gallery-04.png';
import gallery5 from './../images/sidebar-gallery-05.png';
import gallery6 from './../images/sidebar-gallery-06.png';

const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};





class Blog extends React.Component {



  render() {
    return (
     <div>
         <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      Blog
      </HeroText>
    </section>

			 <section class="Portfolio-detail pt-5 mt-5">
          <div class="container">
              <div class="row mx-0 px-0">
                  <div class="col-md-12 col-12">

                      <div class="w-100 d-flex flex-column blog-wrapper pb-5">
                          <div class="w-100 blog-img">
                              <a href="/" target="_blank">
                                  <img class="img-fluid" src={blog1} alt="Blog 01" />
                              </a>
                          </div>
                          <div class="w-100 blog-date pt-4">
                              <a href="/" target="_blank">December 12. 2022</a>
                          </div>
                          <div class="w-100 blog-text">
                              <a href="/" target="_blank">
                                  <h2 class="pt-3 pb-2">
                                      What we do in life echoes in eternity
                                  </h2>
                              </a>
                              <p class="py-1">
                                  At vero eos et accusamus et iusto odio digs imos duis qui blandit is praesentium vlu
                                  ptatum
                                  deleniti atque corrupti quos dolores et quas molestias excepturi.
                              </p>
                          </div>
                          <div class="w-100 blog-tags py-3">
                              <a href="/" target="_blank">design</a>
                              <a href="/" target="_blank">digital</a>
                              <a href="/" target="_blank">online</a>
                          </div>
                      </div>

                      <div class="w-100 d-flex flex-column blog-wrapper pb-5">
                          <div class="w-100 blog-img">
                              <a href="/" target="_blank">
                                  <img class="img-fluid" src={blog2} alt="Blog 02" />
                              </a>
                          </div>
                          <div class="w-100 blog-date pt-4">
                              <a href="/" target="_blank">December 12. 2022</a>
                          </div>
                          <div class="w-100 blog-text">
                              <a href="/" target="_blank">
                                  <h2 class="pt-3 pb-2">
                                      Esports is synonymous with CS:GO
                                  </h2>
                              </a>
                              <p class="py-1">
                                  Alienum phaedrum torquatos nec eu, vis detraxit periculis ex, nihil expetendis in mei.
                                  Mei an pericula euripidis, hinc
                                  partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix
                                  at, vel pertinax sensibus id,
                                  error epicurei mea et mea facilisis.
                              </p>
                          </div>
                          <div class="w-100 blog-tags py-3">
                              <a href="/" target="_blank">artsy</a>
                              <a href="/" target="_blank">design</a>
                              <a href="/" target="_blank">digital</a>
                          </div>
                      </div>

                      <div class="w-100 d-flex flex-column blog-wrapper blog-type-icon py-5 text-center">
                          <div class="w-100 blog-icon mb-5">
                              <img class="img-fluid" src={blogicon1} alt="Blog 01" />
                          </div>
                          <div class="w-100 blog-text">
                              <p class="px-3">
                                  Auctor elit sed vulputate mi sit amet. Viverra orci sagittis eu volutpat odio. Id nibh
                                  tortor id aliquet.
                              </p>
                          </div>
                          <div class="w-100 blog-author">
                              <a href="/" target="_blank">
                                  <h3>
                                      Hideki Kamiya
                                  </h3>
                              </a>
                          </div>
                          <div class="w-100 blog-tags py-3">
                              <a href="/" target="_blank">Game designer</a>
                          </div>
                      </div>

                      <div class="w-100 d-flex flex-column blog-wrapper pb-5">
                          <div class="w-100 blog-img">
                              <a href="/" target="_blank">
                                  <img class="img-fluid" src={blog3} alt="Blog image 03" />
                              </a>
                          </div>
                          <div class="w-100 blog-date pt-4">
                              <a href="/" target="_blank">December 12. 2022</a>
                          </div>
                          <div class="w-100 blog-text">
                              <a href="/" target="_blank">
                                  <h2 class="pt-3 pb-2">
                                      What console shoud you pick & why
                                  </h2>
                              </a>
                              <p class="py-1">
                                  Alienum phaedrum torquatos nec eu, vis detraxit periculis ex, nihil expetendis in mei.
                                  Mei an pericula euripidis, hinc
                                  partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix
                                  at, vel pertinax sensibus id,
                                  error epicurei mea et mea facilisis.
                              </p>
                          </div>
                          <div class="w-100 blog-tags py-3">
                              <a href="/" target="_blank">artsy</a>
                              <a href="/" target="_blank">design</a>
                              <a href="/" target="_blank">digital</a>
                          </div>
                      </div>

                      <div class="w-100 d-flex flex-column blog-wrapper blog-type-icon py-5 text-center">
                          <div class="w-100 blog-icon mb-5">
                              <img class="img-fluid" src={blogicon2} alt="Blog Icon 02" />
                          </div>
                          <div class="w-100 blog-text">
                              <p class="px-3">
                                  Et netus et malesuada fames ac turpis egestas maecenas pharetra. Consequat nisl vel
                                  pretium lectus quam.
                              </p>
                          </div>
                      </div>

                      <div class="w-100 d-flex flex-column blog-wrapper pb-5">
                          <div class="w-100 blog-img">
                              <a href="/" target="_blank">
                                  <img class="img-fluid" src={blog4} alt="Blog image 04" />
                              </a>
                          </div>
                          <div class="w-100 blog-date pt-4">
                              <a href="/" target="_blank">December 12. 2022</a>
                          </div>
                          <div class="w-100 blog-text">
                              <a href="/" target="_blank">
                                  <h2 class="pt-3 pb-2">
                                      Hideo Kojima on the age of conspiracy
                                  </h2>
                              </a>
                              <p class="py-1">
                                  Alienum phaedrum torquatos nec eu, vis detraxit periculis ex, nihil expetendis in mei.
                                  Mei an pericula euripidis, hinc
                                  partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix
                                  at, vel pertinax sensibus id,
                                  error epicurei mea et mea facilisis.
                              </p>
                          </div>
                          <div class="w-100 blog-tags py-3">
                              <a href="/" target="_blank">artsy</a>
                              <a href="/" target="_blank">design</a>
                              <a href="/" target="_blank">digital</a>
                          </div>
                      </div>

                      <div class="w-100 d-flex flex-wrap my-5 gfx-breadcrumbs">
                          <a href="/" target="_self">
                              01
                          </a>
                          <a href="/" target="_self">
                              02
                          </a>
                          <a href="/" target="_self">
                              Next
                          </a>
                      </div>
                  </div>
               
              </div>
          </div>
      </section>
  
		</div>
    );
  }
};

export default Blog;