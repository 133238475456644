import React from "react";
/* import { withRouter } from 'react-router-dom'; */
import './../../App.css';
import {Link} from 'react-router-dom';
import LogoFooter from '../../images/logo-footer.svg';
import Social1 from '../../images/footer-social-icon-01.svg';
import Social2 from '../../images/footer-social-icon-02.svg';
import Social3 from '../../images/footer-social-icon-03.svg';
import Social4 from '../../images/footer-social-icon-04.svg';

class Footer extends React.Component {

  render() {
    return (
        <footer>
        <div class="container-fluid d-flex flex-column justify-content-center align-items-center">
            <div class="logo-footer">
                <img class="img-fluid" src={LogoFooter} alt="logo-footer" />
            </div>
            <div class="menu-footer py-5">
                <nav class="navbar navbar-dark bg-dark d-flex flex-column">
                    <div class="collapse navbar-collapse justify-content-center d-block">
                        <div class="navbar-nav text-center flex-column flex-md-row">
						<Link to="/" class="nav-item nav-link px-2 text-uppercase active" >Home <span
                                    class="sr-only">(current)</span></Link>
						<Link to="/about-us" class="nav-item nav-link text-uppercase px-2" >About Us</Link>
						<Link to="/faq" class="nav-item nav-link text-uppercase px-2" >FAQ</Link>
						<Link to="/portfolio" class="nav-item nav-link text-uppercase px-2" >Portfolio</Link>
						<Link to="/blog" class="nav-item nav-link text-uppercase px-2" >Blog</Link>
						<Link to="/terms-and-conditions" class="nav-item nav-link text-uppercase px-2" >Terms & Conditions</Link>
						<Link to="/privacy-policy" class="nav-item nav-link text-uppercase px-2" >Privacy Policy</Link>
						<Link to="/contact-us" class="nav-item nav-link text-uppercase px-2" >Contact Us</Link>
						
                        </div>
                    </div>
                </nav>
            </div>
            <div class="menu-footer-social d-flex">
                <a href="/#" target="_blank">
                    <img class="img-fluid px-3" src={Social1} />
                </a>
                <a href="/#" target="_blank">
                    <img class="img-fluid px-3" src={Social2} />
                </a>
                <a href="/#" target="_blank">
                    <img class="img-fluid px-3" src={Social3} />
                </a>
                <a href="/#" target="_blank">
                    <img class="img-fluid px-3" src={Social4} />
                </a>
            </div>
            <div class="copyright-text-footer pt-5 pb-0">
                © 2023 GFXhawk, All Rights Reserved
            </div>
        </div>
    </footer>
    );
  }
};

export default Footer;