import React, { useState, useEffect } from "react";

/* import { withRouter } from 'react-router-dom'; */
import './../App.css';
import styled, { keyframes } from 'styled-components';

import portfolio1 from './../images/portfolio-image-01.png';
import portfolio2 from './../images/portfolio-image-02.png';
import portfolio3 from './../images/portfolio-image-03.png';
import portfolio4 from './../images/portfolio-image-04.png';

const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};



class Portfolio extends React.Component {



  render() {
    return (
     <div>
         <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      Portfolio
      </HeroText>
    </section>

        <section class="Portfolio-list pt-5 mt-5">
            <div class="container">
                <div class="row justify-content-center align-items-center">
                <div class="col-lg-6 col-md-6 col-12">
                        <a class="Portfolio-list-wrapper  d-flex flex-column w-100" href="#" target="_blank">
                            <div class="Portfolio-list-image">
                                <img class="img-fluid" src={portfolio1} alt="portfolio-img" />
                            </div>
                            <div class="Portfolio-list-title">Dead souls</div>
                            <div class="Portfolio-list-category">Concept</div>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <a class="Portfolio-list-wrapper  d-flex flex-column w-100" href="#" target="_blank">
                            <div class="Portfolio-list-image">
                                <img class="img-fluid" src={portfolio2} alt="portfolio-img" />
                            </div>
                            <div class="Portfolio-list-title">Lightnes</div>
                            <div class="Portfolio-list-category">Concept</div>
                        </a>
                    </div>
                    
                    <div class="col-lg-6 col-md-6 col-12">
                        <a class="Portfolio-list-wrapper  d-flex flex-column w-100" href="#" target="_blank">
                            <div class="Portfolio-list-image">
                                <img class="img-fluid" src={portfolio3} alt="portfolio-img" />
                            </div>
                            <div class="Portfolio-list-title">Dead souls</div>
                            <div class="Portfolio-list-category">Concept</div>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <a class="Portfolio-list-wrapper  d-flex flex-column w-100" href="#" target="_blank">
                            <div class="Portfolio-list-image">
                                <img class="img-fluid" src={portfolio4} alt="portfolio-img" />
                            </div>
                            <div class="Portfolio-list-title">Lightnes</div>
                            <div class="Portfolio-list-category">Concept</div>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <a class="Portfolio-list-wrapper  d-flex flex-column w-100" href="#" target="_blank">
                            <div class="Portfolio-list-image">
                                <img class="img-fluid" src={portfolio1} alt="portfolio-img" />
                            </div>
                            <div class="Portfolio-list-title">Forces</div>
                            <div class="Portfolio-list-category">Game</div>
                        </a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <a class="Portfolio-list-wrapper  d-flex flex-column w-100" href="#" target="_blank">
                            <div class="Portfolio-list-image">
                                <img class="img-fluid" src={portfolio2} alt="portfolio-img" />
                            </div>
                            <div class="Portfolio-list-title">Dead souls</div>
                            <div class="Portfolio-list-category">Game</div>
                        </a>
                    </div>
                  
                </div>
            </div>
        </section>
   
		</div>
    );
  }
};

export default Portfolio;