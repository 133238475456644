import { BrowserRouter as Router, Routes, Route }from 'react-router-dom';
import Header from './Pages/template/Header';
import Footer from './Pages/template/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Faq from './Pages/Faq';
import Contact from './Pages/Contact';
import Portfolio from './Pages/Portfolio';
import PortfolioDetail from './Pages/PortfolioDetail';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import TermsEndConditions from './Pages/TermsEndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ErrorPage from './Pages/ErrorPage';
import React from 'react'; 
// import navigation from './navigation';



class App extends React.Component {



render()
{

  
  return (

    
    <div className="App">
        <Router>
          <Header />
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path='/about-us' element={<About />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/contact-us' element={<Contact />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/portfolio-detail' element={<PortfolioDetail />} />
                <Route path='/blog' element={<Blog />} />
                <Route path='/blog-detail' element={<BlogDetail />} />
                <Route path='/terms-and-conditions' element={<TermsEndConditions />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='*' element={<ErrorPage />}/>
            </Routes>
			    <Footer />
        </Router>
    </div>
  );
}
}


export default App;
