import React from 'react';
import styled from 'styled-components';

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff !important;
  width: ${props => props.width}%;
`;

const ProgressBar = ({ label, stats }) => {
  return (
    <div className="progress-bar-wrapper py-3">
      <div className="progress">
        <ProgressBarFill className="progress-bar-fill" width={stats} />
      </div>
      <div className="d-flex justify-content-between w-100 my-2">
        <div className="progress-bar-label">
          {label}
        </div>
        <div className="progress-bar-stats">
          {stats}%
        </div>
      </div>
    </div>
  );
};

const ProgressBars = () => {
  return (
    <div className="col-md-5 col-12 pt-5">
      <ProgressBar label="Concept art" stats={25} />
      <ProgressBar label="Animation" stats={57} />
      <ProgressBar label="3d modeling" stats={69} />
      <ProgressBar label="Development" stats={43} />
    </div>
  );
};

export default ProgressBars;