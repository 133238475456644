import React from "react";
/* import { withRouter } from 'react-router-dom'; */
import './../../App.css';
import {Link} from 'react-router-dom';
import Logo from '../../images/logo.svg';
// import { useNavigate } from 'react-router-dom';
// import { withNavigate } from '../navigation';

class Header extends React.Component {

/*   constructor(props) {
    super(props)
      this.state = {
          dineIN: [
            { 
              table_no: 'A1', 
              time: '30 min',  
              order_no: 1659,
              date: '1 May',
            },
            { 
              table_no: 'A2', 
              time: '10 min',  
              order_no: 1657,
              date: '1 May',
            },
            { 
              table_no: 'A3', 
              time: '20 min',  
              order_no: 1658,
              date: '1 May',
            },
            { 
              table_no: 'A4', 
              time: '20 min',  
              order_no: 1658,
              date: '1 May',
            },
            { 
              table_no: 'A4', 
              time: '20 min',  
              order_no: 1658,
              date: '1 May',
            }
          ],
          takeAWAY: [
            { 
              table_no: 'A1', 
              time: '30 min',  
              order_no: 1654,
              date: '1 May',
            },
            { 
              table_no: 'A2', 
              time: '10 min',  
              order_no: 1655,
              date: '1 May',
            },
            { 
              table_no: 'A3', 
              time: '20 min',  
              order_no: 1651,
              date: '1 May',
            },
            { 
              table_no: 'A4', 
              time: '20 min',  
              order_no: 1652,
              date: '1 May',
            },
            { 
              table_no: 'A5', 
              time: '20 min',  
              order_no: 1652,
              date: '1 May',
            }
          ]
      }    
  } */

  componentDidMount() {

  } 

  render() {
    return (
        <header>
        <nav class="navbar navbar-expand-md navbar-dark bg-dark px-md-5">
        <Link to="/" class="navbar-brand" ><img class="img-fluid logo-header" src={Logo} alt="header-logo" width="120"
                    height="150" /></Link>
            <button class="navbar-toggler ml-auto p-0" type="button" data-toggle="collapse"
                data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon">
				{ /* <svg class="mob-menu-icon-svg" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" width="47.25" height="35" viewBox="0 0 47.25 35">
                        <path
                            d="M1.25 35a1.176 1.176 0 0 1-.9-.375A1.242 1.242 0 0 1 0 33.75V1.25a1.218 1.218 0 0 1 .35-.9 1.218 1.218 0 0 1 .9-.35H24.6a1.218 1.218 0 0 1 .9.35 1.218 1.218 0 0 1 .35.9v4.7a1.218 1.218 0 0 1-.35.9 1.218 1.218 0 0 1-.9.35H8.65v6.75H23.5a1.176 1.176 0 0 1 .9.375 1.3 1.3 0 0 1 .35.925v4.35a1.242 1.242 0 0 1-.35.875 1.176 1.176 0 0 1-.9.375H8.65v6.95H25a1.218 1.218 0 0 1 .9.35 1.218 1.218 0 0 1 .35.9v4.7a1.242 1.242 0 0 1-.35.875A1.176 1.176 0 0 1 25 35Z">
                        </path>
                        <path
                            d="M46 35a1.176 1.176 0 0 0 .9-.375 1.242 1.242 0 0 0 .35-.875V1.25a1.218 1.218 0 0 0-.35-.9A1.218 1.218 0 0 0 46 0H22.65a1.218 1.218 0 0 0-.9.35 1.218 1.218 0 0 0-.35.9v4.7a1.218 1.218 0 0 0 .35.9 1.218 1.218 0 0 0 .9.35H38.6v6.75H23.75a1.176 1.176 0 0 0-.9.375 1.3 1.3 0 0 0-.35.925v4.35a1.242 1.242 0 0 0 .35.875 1.176 1.176 0 0 0 .9.375H38.6v6.95H22.25a1.218 1.218 0 0 0-.9.35 1.218 1.218 0 0 0-.35.9v4.7a1.242 1.242 0 0 0 .35.875 1.176 1.176 0 0 0 .9.375Z">
                        </path>
				</svg> */ }
                </span>
            </button>
            <div class="collapse navbar-collapse justify-content-md-center justify-content-sm-right"
                id="navbarNavAltMarkup">
                <div class="navbar-nav text-right">
				
				<Link to="/" class="nav-item nav-link active" >Home <span
                            class="sr-only">(current)</span></Link>
				
				<Link to="/about-us" class="nav-item nav-link" >About Us</Link>
				<Link to="/portfolio" class="nav-item nav-link" >Portfolio</Link>
				<Link to="/contact-us" class="nav-item nav-link" >Contact Us</Link>
				
                </div>
            </div>
            <div>
			
            </div>
        </nav>
    </header>
    );
  }
};

export default Header;