import React, { useState, useEffect } from "react";
/* import { withRouter } from 'react-router-dom'; */
import './../App.css';
import styled, { keyframes } from 'styled-components';
import Social1 from './../images/footer-social-icon-01.svg';
import Social2 from './../images/footer-social-icon-02.svg';
import Social3 from './../images/footer-social-icon-03.svg';
import Social4 from './../images/footer-social-icon-04.svg';
import portdolio1 from './../images/portdolio-detail-01.png';
import portdolio2 from './../images/portdolio-detail-02.png';
import portdolio3 from './../images/portdolio-detail-03.png';


const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};



class PortfolioDetail extends React.Component {



  render() {
    return (
     <div>
        <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      Portfolio Detail
      </HeroText>



    </section>

    <section class="Portfolio-detail mt-5">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 py-4">
                    <a href="#" target="_blank" class="gfx-product-detail-image-wrapper">
                        <img class="img-fluid" src={portdolio1}
                            alt="portfolio detail img 01" />
                    </a>
                </div>
                <div class="col-12 py-4">
                    <a href="#" target="_blank" class="gfx-product-detail-image-wrapper">
                        <img class="img-fluid" src={portdolio2}
                            alt="portfolio detail img 02" />
                    </a>
                </div>
                <div class="col-12 py-4">
                    <a href="#" target="_blank" class="gfx-product-detail-image-wrapper">
                        <img class="img-fluid" src={portdolio3}
                            alt="portfolio detail img 03" />
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9 col-12 pt-2">
                    <h3>
                        Void palace
                    </h3>
                    <p>
                        At vero eos et accusamus et iusto odio digs imos ducimus qui blandits pra esentium vlu ptatumdel
                        eniti atque corrupti
                        quos dolores et quas molestia excepturi sint occaecati cupiditate non provident, similique sunt
                        in culp qui officia
                        deserunt mollitia animi.
                    </p>
                </div>
                <div class="col-md-3 col-12 pt-2">
                    <div class="gfx-address d-flex flex-column">
                        <span class="w-100 useful-links1 pb-3">
                            <h3>Category:</h3>
                            <p>
                               Designing
                            </p>

 <p>
                               Games
                            </p>

 <p>
                               Gaming
                            </p>
 <p>
                               Hardware
 </p>

                        </span>
                        <span class="w-100 useful-links sidebar-tags pb-5">
                            <h3>Tags:</h3>
                            <p>
                                <a href="tel:+024993223" target="_blank">Design</a>
                                <a href="tel:+024993223" target="_blank">Digital</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                                <a href="tel:+024993223" target="_blank">Online</a>
                            </p>

                        </span>
                        <span class="w-100 useful-links">
                            <h3>Date:</h3>
                            <p>
                                <a href="tel:+024993223" target="_blank">December 12. 2022</a>
                            </p>

                        </span>
                        <span class="w-100 useful-links">
                            <h3>Share:</h3>
                            <p>
                            <a href="#" target="_blank">
                                      <img class="img-fluid" src={Social1} />
                                  </a>
                                  <a href="#" target="_blank">
                                      <img class="img-fluid" src={Social2} />
                                  </a>
                                  <a href="#" target="_blank">
                                      <img class="img-fluid" src={Social3} />
                                  </a>
                                  <a href="#" target="_blank">
                                      <img class="img-fluid" src={Social4} />
                                  </a>
                            </p>

                        </span>
                    </div>
                </div>
            </div>
          
        </div>
    </section>
 

		</div>
    );
  }
};

export default PortfolioDetail;