import React, { useEffect, useRef } from 'react';

/* import { withRouter } from 'react-router-dom'; */
import './../App.css';
import styled, { keyframes } from 'styled-components';

//  import '../css/slick.css';
import about1 from './../images/about-us-feature.png';

import Service1 from './../images/timeline-hero-01.png';
import Service2 from './../images/timeline-hero-02.png';
import Service3 from './../images/timeline-hero-03.png';
import Service4 from './../images/timeline-hero-04.png';
import video1 from './../images/video.mp4';
import feature1 from './../images/feature-blog-01.png';
import feature2 from './../images/feature-blog-02.png';
import feature3 from './../images/feature-blog-03.png';
import feature4 from './../images/feature-blog-04.png';
import AboutSlider from "./include/AboutSlider";
import ProgressBars from './include/ProgressBars';


const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const HeroSeparator = styled.div`
  z-index: 9999;
  position: absolute;
  width: 2px;
  background-color: #fff;
  display: block;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

const HeroSectionWrapper = styled.div`
  position: relative;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};
// import jqueryJs from '../js/jquery_3.6.4.js';
// import slickJs from '../js/slick.min.js';



  



const About = () => {
    useEffect(() => {
        const video = document.getElementById('myVideo');
    
        video.addEventListener('timeupdate', function() {
          if (video.currentTime >= 20) {
            video.currentTime = 0;
          }
        });
      }, []);


      const heroSectionRef1 = useRef("");

      useEffect(() => {
        const heroSection1 = heroSectionRef1.current;
        const heroSeparator1 = heroSection1.querySelector(".hero-separator");
    
        const heroHeight1 = heroSection1.offsetHeight;
        heroSeparator1.style.height = heroHeight1 + "px";
      }, []);

      const heroSectionRef2 = useRef("");

      useEffect(() => {
        const heroSection2 = heroSectionRef2.current;
        const heroSeparator2 = heroSection2.querySelector(".hero-separator");
    
        const heroHeight2 = heroSection2.offsetHeight;
        heroSeparator2.style.height = heroHeight2 + "px";
      }, []);


    return (
      <div>
        

        <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
     About Us
      </HeroText>
    </section>

    <HeroSectionWrapper ref={heroSectionRef1} className="hero-timeline my-5">
        <div class="container">
            <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-2 py-md-0 py-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service1} alt="timeline hero 01" />
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-1  py-md-0 py-2">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-1">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service2} alt="timeline hero 02" />
                    </div>
                </div>
            </div>
            <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-2 py-md-0 py-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service3} alt="timeline hero 03" />
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-1  py-md-0 py-2">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
            </div>
           
        </div>
        <HeroSeparator heroSectionRef={heroSectionRef1} className="hero-separator d-none d-md-block" />

    </HeroSectionWrapper>
    <section class="gfx-video">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <video id="myVideo" class="gfx-video px-md-5" autoPlay muted loop>
                            <source src={video1} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                    </div>
                </div>
            </div>
        </section>

        <HeroSectionWrapper ref={heroSectionRef2} className="hero-timeline my-5">
        {/* <div class="hero-sepratorwwwwww hero-seprator1 d-none d-md-block"></div> */}
        <div class="container">
           <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-1">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service4} alt="timeline hero 02" />
                    </div>
                </div>
            </div>
            <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-2 py-md-0 py-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service1} alt="timeline hero 03" />
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-1  py-md-0 py-2">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-1">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service2} alt="timeline hero 04" />
                    </div>
                </div>
            </div>
           
        </div>
        <HeroSeparator heroSectionRef={heroSectionRef2} className="hero-separator d-none d-md-block" />

        </HeroSectionWrapper>

        <section class="gfx-address py-5 my-5">
            <div class="container-fluid">
                <div class="w-100">
                    <AboutSlider />
                </div>
            </div>
        </section>
        <section className="contact-us-form">
        <div className="container">
          <div className="row justify-content-between">
            <ProgressBars />
            <div className="col-md-6 col-12 pt-5">
              <div className="contact-us-description text-md-right text-center">
                <h2>
                  The way we work is of the greatest importance
                </h2>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                  voluptatum deleniti atque.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
		</div>
    )

}

export default About;