import React, { useState, useEffect } from "react";
import styled, { keyframes } from 'styled-components';

const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};




const PrivacyPolicy = () => {


    // useEffect(() => {
    //     const script = document.createElement("script")
    //     script.src =
    //     jqueryJs
    //     script.async = true
    //     script.defer = true
    //     document.body.appendChild(script)

    //     const script1 = document.createElement("script")
    //     script1.src =
    //     slickJs
    //     script1.async = true
    //     script1.defer = true
    //     document.body.appendChild(script1)
    
    //     return () => {
    //       // clean up the script when the component in unmounted
    //       document.body.removeChild(script)
    //       document.body.removeChild(script1)
    //     }
    //   }, []);

    return (
      <div>
        <section class="hero-banner mb-5">
        <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      Privacy Policy
      </HeroText>
    </section>
    
    <section class="FAQ">
        <div class="container">
            <div class="row">
                 <div class="col-md-12 col-12">
                
                        <h2 class="pt-3 pb-2">
                                   Privacy Policy
                                </h2>
                           
                            <p class="py-1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Pellentesque elit
                                eget gravida cum sociis natoque
                                penatibus et magnis. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat.
                                Vitae congue eu consequat ac
                                felis donec et odio. Elementum sagittis vitae et leo duis.
                                <span class="gfx-blog-quote py-4 pl-5 pr-3 d-block">
                                    “Sit amet est placerat in egestas. Mi ipsum faucibus vitae aliquet nec ullamcorper
                                    sit amet risus. Ultrices sagittis
                                    orci a scelerisque purus suis lorem vitae aligue sit amet uis.”
                                </span>
                                Viverra nibh vitae sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Velit scelerisque in dictum non. Hac habitasse platea dictumst quisque sagittis
                                purus sit amet volutpat. Mattis
                                ullamcorper velit sed ullamcorper morbi tincidunt ornare. Arcu dui vivamus arcu felis
                                bibendum. Sit amet dictum sit amet
                                justo donec enim diam. Mollis aliquam ut porttitor leo a diam sollicitudin tempor. Et
                                sollicitudin ac orci phasellus
                                egestas tellus rutrum. Morbi tristique senectus et netus et malesuada fames. Malesuada
                                fames ac turpis egestas maecenas.
                                Non arcu risus quis varius quam quisque id. Duis ultricies lacus sed turpis tincidunt
                                id. Sollicitudin tempor id eu nisl
                                nunc mi ipsum. Odio facilisis mauris sit amet massa vitae tortor. Tincidunt ornare massa
                                eget egestas purus.
                            </p>

                            <h2 class="pt-3 pb-2">
                                Transfiguring Gaming Industry
                            </h2>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Enim diam vulputate ut pharetra sit amet aliquam. Porta nibh venenatis cras sed
                                felis eget velit aliquet.
                                Convallis posuere morbi leo urna. Quam lacus suspendisse faucibus interdum posuere
                                lorem. Sodales ut etiam sit amet. Sed
                                turpis tincidunt id aliquet risus feugiat. Pellentesque diam volutpat commodo sed
                                egestas egestas fringilla phasellus.
                                Faucibus a pellentesque sit amet. Libero justo laoreet sit amet cursus.
                                <br /><br />
                                Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Sed velit
                                dignissim sodales ut eu sem. Tellus
                                rutrum tellus pellentesque eu tincidunt tortor aliquam. Pellentesque adipiscing commodo
                                elit at imperdiet dui accumsan.
                                Vitae purus faucibus ornare suspendisse sed nisi lacus sed.
                            </p>

                            <h2 class="pt-3 pb-2">
                                advantage of Cloud-Based Gaming
                            </h2>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Enim diam vulputate ut pharetra sit amet aliquam. Porta nibh venenatis cras sed
                                felis eget velit aliquet.
                                Convallis posuere morbi leo urna. Quam lacus suspendisse faucibus interdum posuere
                                lorem. Sodales ut etiam sit amet. Sed
                                turpis tincidunt id aliquet risus feugiat. Pellentesque diam volutpat commodo sed
                                egestas egestas fringilla phasellus.
                                Faucibus a pellentesque sit amet. Libero justo laoreet sit amet cursus.
                            </p>

                </div>
        
            </div>
        </div>
    </section>
		</div>
    )

}

export default PrivacyPolicy;