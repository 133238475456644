import React, { useState, useEffect } from "react";

/* import { withRouter } from 'react-router-dom'; */
import './../App.css';
import styled, { keyframes } from 'styled-components';

import Social1 from './../images/footer-social-icon-01.svg';
import Social2 from './../images/footer-social-icon-02.svg';
import Social3 from './../images/footer-social-icon-03.svg';
import Social4 from './../images/footer-social-icon-04.svg';
import blog1 from './../images/blog-01.png';

const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};

/* import { withRouter } from 'react-router-dom'; */
// import './../App.css';






class BlogDetail extends React.Component {



  render() {
    return (
     <div>
          <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      BLog Detail
      </HeroText>
    </section>
			 <section class="Portfolio-detail pt-5 mt-5">
        <div class="container">
            <div class="row mx-0 px-0">
                <div class="col-md-9 col-12">

                    <div class="w-100 d-flex flex-column blog-wrapper pb-5">
                        <div class="w-100 blog-img">
                            <a href="#" target="_blank">
                                <img class="img-fluid" src={blog1} alt="Blog image 01" />
                            </a>
                        </div>
                        <div class="w-100 blog-date pt-4">
                            <a href="#" target="_blank">December 12. 2022</a>
                        </div>
                        <div class="w-100 blog-text">
                            <a href="#" target="_blank">
                                <h2 class="pt-3 pb-2">
                                    What we do in life echoes in eternity
                                </h2>
                            </a>
                            <p class="py-1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat. Pellentesque elit
                                eget gravida cum sociis natoque
                                penatibus et magnis. Viverra nibh cras pulvinar mattis nunc sed blandit libero volutpat.
                                Vitae congue eu consequat ac
                                felis donec et odio. Elementum sagittis vitae et leo duis.
                                <span class="gfx-blog-quote py-4 pl-5 pr-3 d-block">
                                    “Sit amet est placerat in egestas. Mi ipsum faucibus vitae aliquet nec ullamcorper
                                    sit amet risus. Ultrices sagittis
                                    orci a scelerisque purus suis lorem vitae aligue sit amet uis.”
                                </span>
                                Viverra nibh vitae sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Velit scelerisque in dictum non. Hac habitasse platea dictumst quisque sagittis
                                purus sit amet volutpat. Mattis
                                ullamcorper velit sed ullamcorper morbi tincidunt ornare. Arcu dui vivamus arcu felis
                                bibendum. Sit amet dictum sit amet
                                justo donec enim diam. Mollis aliquam ut porttitor leo a diam sollicitudin tempor. Et
                                sollicitudin ac orci phasellus
                                egestas tellus rutrum. Morbi tristique senectus et netus et malesuada fames. Malesuada
                                fames ac turpis egestas maecenas.
                                Non arcu risus quis varius quam quisque id. Duis ultricies lacus sed turpis tincidunt
                                id. Sollicitudin tempor id eu nisl
                                nunc mi ipsum. Odio facilisis mauris sit amet massa vitae tortor. Tincidunt ornare massa
                                eget egestas purus.
                            </p>

                            <h2 class="pt-3 pb-2">
                                Transfiguring Gaming Industry
                            </h2>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Enim diam vulputate ut pharetra sit amet aliquam. Porta nibh venenatis cras sed
                                felis eget velit aliquet.
                                Convallis posuere morbi leo urna. Quam lacus suspendisse faucibus interdum posuere
                                lorem. Sodales ut etiam sit amet. Sed
                                turpis tincidunt id aliquet risus feugiat. Pellentesque diam volutpat commodo sed
                                egestas egestas fringilla phasellus.
                                Faucibus a pellentesque sit amet. Libero justo laoreet sit amet cursus.
                                <br /><br />
                                Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Sed velit
                                dignissim sodales ut eu sem. Tellus
                                rutrum tellus pellentesque eu tincidunt tortor aliquam. Pellentesque adipiscing commodo
                                elit at imperdiet dui accumsan.
                                Vitae purus faucibus ornare suspendisse sed nisi lacus sed.
                            </p>

                            <h2 class="pt-3 pb-2">
                                advantage of Cloud-Based Gaming
                            </h2>

                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna
                                aliqua. Enim diam vulputate ut pharetra sit amet aliquam. Porta nibh venenatis cras sed
                                felis eget velit aliquet.
                                Convallis posuere morbi leo urna. Quam lacus suspendisse faucibus interdum posuere
                                lorem. Sodales ut etiam sit amet. Sed
                                turpis tincidunt id aliquet risus feugiat. Pellentesque diam volutpat commodo sed
                                egestas egestas fringilla phasellus.
                                Faucibus a pellentesque sit amet. Libero justo laoreet sit amet cursus.
                            </p>

                        </div>
                        <div class="w-100 blog-tags py-3">
                            <a href="#" target="_blank">design</a>
                            <a href="#" target="_blank">digital</a>
                            <a href="#" target="_blank">online</a>
                        </div>
                    </div>

                

                    <div class="w-100 d-md-flex d-block blog-wrapper blog-type-icon py-5 px-5">
                      
                        <div class="blog-text py-3">
                            <h3 class="px-md-4">
                                Jaidan Dorsey
                            </h3>
                            <p class="px-md-4">
                                Ipsum consequat nisl vel pretium lectus quam id leo in. Aliquam eleifend mi in nulla.
                                Tempor id eu nisl nunc mi.
                            </p>
                        </div>
                    </div>

                    <div class="w-100 d-md-flex d-block blog-wrapper blog-type-icon px-3">
                        <div class="blog-text py-3">
                            <h3 class="px-4 mb-0">
                                03 Comments
                            </h3>
                        </div>
                    </div>

                    <div class="w-100 d-md-flex d-block blog-wrapper blog-comment">
                      
                        <div class="blog-text py-3">
                            <h3 class="px-md-4 mb-0">
                                Meri Manana
                            </h3>
                            <span class="comment-date px-4">December 12. 2022</span>
                            <p class="px-md-4 pt-4">
                                Nisi est sit amet facilisis magna etiam. Risus in hendrerit gravida rutrum. Justo nec
                                ultrices dui sapien eget mi proin.
                            </p>
                        </div>
                        <div class="comment-reply ml-auto">
                            <a href="#" target="_self">
                                Reply
                            </a>
                        </div>
                    </div>

                    <div class="w-100 d-md-flex d-block blog-wrapper blog-comment-reply pl-5">
                       
                        <div class="comment-text py-3">
                            <h3 class="px-md-4 mb-0">
                                Meri Manana
                            </h3>
                            <span class="comment-date px-4">December 12. 2022</span>
                            <p class="px-md-4 pt-4">
                                Aliquet eget sit amet tellus cras. Sed viverra ipsum nunc aliquet bibendum enim
                                facilisis gravida neque.
                            </p>
                        </div>
                        <div class="comment-reply ml-auto">
                            <a href="#" target="_self">
                                Reply
                            </a>
                        </div>
                    </div>

                    <div class="w-100 d-md-flex d-block blog-wrapper blog-comment">
                       
                        <div class="blog-text py-3">
                            <h3 class="px-md-4 mb-0">
                                Melita Hwang
                            </h3>
                            <span class="comment-date px-4">December 12. 2022</span>
                            <p class="px-md-4 pt-4">
                                Vestibulum lectus mauris ultrices eros in cursus turpis. Arcu cursus vitae congue mauris
                                rhoncus. Massa id neque aliquam
                                vestibulum.
                            </p>
                        </div>
                        <div class="comment-reply ml-auto">
                            <a href="#" target="_self">
                                Reply
                            </a>
                        </div>
                    </div>

                    <div class="w-100 d-flex flex-column leave-reply py-5">
                        <h2 class="pt-3">
                            Leave a Reply
                        </h2>
                        <p>
                            Your email address will not be published. Required fields are marked
                        </p>
                        <div class="form-wrapper">
                            <form>
                            <div class="form-group">
                                    <textarea class="form-control gfx-input" placeholder="Your comment...*" id="message"
                                        rows="10"></textarea>
                                    <small id="smallmessage" class="form-text text-muted d-none">Please fill out this
                                        field.</small>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control gfx-input" id="name"
                                        placeholder="Your name...*" />
                                    <small id="smallname" class="form-text text-muted d-none">Please fill out this
                                        field.</small>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control gfx-input" id="email"
                                        placeholder="Your email...*" />
                                    <small id="smallemail" class="form-text text-muted d-none">Please fill out this
                                        field.</small>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control gfx-input" id="website"
                                        placeholder="Your platform..." />
                                    <small id="smallname" class="form-text text-muted d-none">Please fill out this
                                        field.</small>
                                </div>
                                <div class="form-group d-flex">
                                    <input type="checkbox" class="form-control gfx-input gfx-checkbox"
                                        id="saveDetail" />
                                    <label for="saveDetail" class="form-text text-checkbox">Save my name, email, and
                                        website in this browser for the next time I comment.</label>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary btn-gfx w-100"><b>Post
                                            Comment</b></button>
                                </div>
                              </form>  
                        </div>
                    </div>
                </div>

                <div class="col-md-3 col-12">

<div class="gfx-address d-flex flex-column">
   
    <span class="w-100 useful-links1 pb-5">
        <h3>Category:</h3>
          <p>
           Designing
        </p>

<p>
           Games
        </p>

<p>
           Gaming
        </p>
<p>
           Hardware
</p>

    </span>
    <span class="w-100 useful-links sidebar-tags pb-5">
        <h3>Tags:</h3>
        <p>
            <a href="#" target="_blank">artsy</a>
            <a href="#" target="_blank">developer</a>
            <a href="#" target="_blank">sports</a>
            <a href="#" target="_blank">Design</a>
            <a href="#" target="_blank">Digital</a>
            <a href="#" target="_blank">gameplay</a>
            <a href="#" target="_blank">marketing</a>
        </p>

    </span>
    <span class="w-100 useful-links">
        <h3>Follow Us:</h3>
        <p>
        <a href="/" target="_blank">
                                      <img class="img-fluid" src={Social1} />
                                  </a>
                                  <a href="/" target="_blank">
                                      <img class="img-fluid" src={Social2} />
                                  </a>
                                  <a href="/" target="_blank">
                                      <img class="img-fluid" src={Social3} />
                                  </a>
                                  <a href="/" target="_blank">
                                      <img class="img-fluid" src={Social4} />
                                  </a>
        </p>

    </span>
</div>
</div>


            </div>
        </div>
    </section>
		</div>
    );
  }
};

export default BlogDetail;