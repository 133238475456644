import React, { Component } from "react";

import {useState} from 'react'
import Slider from 'react-slick'
// import {FaChevronLeft, FaChevronRight} from 'react-icons'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './../../slick-custom.css';
import slide1 from '../../images/slide-01.png';
import slide2 from '../../images/slide-02.png';
import slide3 from '../../images/slide-03.png';
import nextArrowSlider from '../../images/slider-arrow-next.svg';
import preArrowSlider from '../../images/slider-arrow-prev.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img class="img-fluid" src={nextArrowSlider} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
       <img class="img-fluid" src={preArrowSlider} />
    </div>
  );
}

export default function AboutSlider() {
  const [sliderRef, setSliderRef] = useState(null)

  const sliderSettings = {
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // infinite: false,

    // fade: true ,
    // speed: 500, // ms
    autoplay: true,
    // initialSlide: 2,
    // lazyLoad: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
         slidesToShow: 1,
        }
      }
    ]


  }

  const hotelCards = [
    {
      imageSrc:slide1,
      title: 'Studio Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 50/Day',
      features: ['Free Wifi', 'Free breakfast'],
    },
    {
      imageSrc:slide2,
      title: 'Deluxe Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 80/Day',
      features: ['Free Wifi', 'Free breakfast'],
    },
    {
      imageSrc:slide3,
      title: 'King Deluxe Room',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 150/Day',
      features: ['Free Wifi', 'Free breakfast', 'Discounted Meals'],
    },
    {
      imageSrc:slide1,
      title: 'Royal Suite',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 299/Day',
      features: [
        'Free Wifi',
        'Free breakfast',
        'Discounted Meals',
        "MacBook for work use (hotel's property)",
      ],
    },
    
    {
      imageSrc:slide2,
      title: 'Royal Suite',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 299/Day',
      features: [
        'Free Wifi',
        'Free breakfast',
        'Discounted Meals',
        "MacBook for work use (hotel's property)",
      ],
    },
    
    {
      imageSrc:slide3,
      title: 'Royal Suite',
      description: 'Lorem ipsum dolor sit amet, consectur dolori',
      pricingText: 'USD 299/Day',
      features: [
        'Free Wifi',
        'Free breakfast',
        'Discounted Meals',
        "MacBook for work use (hotel's property)",
      ],
    },
  ]

  return (
    <div class="slide-center w-100">
    {/* <button onClick={sliderRef?.slickPrev}>
       LEFT
      </button>
      <button onClick={sliderRef?.slickNext}>
       RIGHT
      </button> */}
    <Slider ref={setSliderRef} {...sliderSettings}>
      {hotelCards.map((card, index) => (
        <div key={index} class="slide-itemss">
          <img src={card.imageSrc} class="img-fluid" alt="slide item 02" />                     
        </div>
      ))}
    </Slider>
  </div>
  )
}








