import React, { useState, useEffect } from "react";
/* import { withRouter } from 'react-router-dom'; */
import './../App.css';

import styled, { keyframes } from 'styled-components';

const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};


class Contact extends React.Component {



  render() {
    return (
     <div>
           <section class="hero-banner mb-5">
           <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      Contact Us
      </HeroText>
    </section>

          <section class="contact-us-form">
              <div class="container">
                  <div class="row">
                      <div class="col-md-5 col-12 order-2 order-md-1 pt-5">
                          <div class="form-wrapper">
                              <form>
                              <div class="form-group">
                                <input type="text" class="form-control gfx-input" id="name" placeholder="Your name...*" />
                                <small id="smallname" class="form-text text-muted d-none">Please fill out this
                                    field.</small>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control gfx-input" id="email"
                                    placeholder="Your email...*" />
                                <small id="smallemail" class="form-text text-muted d-none">Please fill out this
                                    field.</small>
                            </div>
                             <div class="form-group">
                                <input type="text" class="form-control gfx-input" id="phone"
                                    placeholder="Your phone..." />
                                <small id="smallemail" class="form-text text-muted d-none">Please fill out this
                                    field.</small>
                            </div>
                             <div class="form-group">
                                <input type="file" class="form-control gfx-input" id="file"
                                    placeholder="Your File..." />
                                <small id="smallemail" class="form-text text-muted d-none">Please fill out this
                                    field.</small>
                            </div>
                            <div class="form-group">
                                <textarea class="form-control gfx-input" placeholder="Your message..." id="message"
                                    rows="6"></textarea>
                                <small id="smallmessage" class="form-text text-muted d-none">Please fill out this
                                    field.</small>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary btn-gfx w-100"><b>Submit</b></button>
                            </div>
                                </form>  
                          </div>
                      </div>
                      <div class="col-md-6 offset-md-1 col-12 order-1 order-md-2 pt-5">
                          <div class="contact-us-description">
                              <h2>
                                  Contact us about press matters, potential sponsors & more.
                              </h2>
                              <div class="gfx-address d-flex flex-md-row flex-column pt-5">
                                  <span class="w-100">
                                      <h3>Stockholm</h3>
                                      <p>
                                          Fäbodvägen 8<br/>142 33<br/>Skogås<br/>
                                          <a href="tel:+024993223" target="_blank">024993223</a>
                                      </p>

                                  </span>
                                  <span class="w-100">
                                      <h3>Glasgow</h3>
                                      <p>11 Lethington Rd,<br/>Glasgow, G46 6TA<br/>
                                          <a href="tel:+381629332193" target="_blank">381629332193</a>
                                      </p>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
		</div>
    );
  }
};

export default Contact;