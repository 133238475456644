import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
/* import { withRouter } from 'react-router-dom'; */
import './../App.css';
import styled, { keyframes } from 'styled-components';



import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './../slick-custom.css';


import Banner1 from './../images/hero-sowrd.png';
import Banner2 from './../images/hero.png';
import Banner3 from './../images/hero-text.png';
import Banner4 from './../images/scroll-down.svg';
import Service1 from './../images/timeline-hero-01.png';
import Service2 from './../images/timeline-hero-02.png';
import Service3 from './../images/timeline-hero-03.png';
import Service4 from './../images/timeline-hero-04.png';
import Character1 from './../images/character-01.png';
import Character2 from './../images/character-02.png';
import Character3 from './../images/character-03.png';
import Character4 from './../images/character-04.png';
import Character5 from './../images/character-05.png';
import features1 from './../images/character-features.png';
import team1 from './../images/team-01.png';
import team2 from './../images/team-02.png';
import team3 from './../images/team-03.png';
import nextArrowSlider from '../images/slider-arrow-next.svg';
import preArrowSlider from '../images/slider-arrow-prev.svg';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <img class="img-fluid" src={nextArrowSlider} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
       <img class="img-fluid" src={preArrowSlider} />
    </div>
  );
}


const float2 = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroText = styled.div`
  animation: ${float2} 1s ease-in-out infinite alternate;
  font-size: 190px !important;
`;

const float = keyframes`
  0% { top: 42%; }
  100% { top: 40%; }
`;

const HeroImage = styled.div`
  animation: ${float} 1s ease-in-out infinite alternate;
  font-size: 190px !important;
`;

const HeroSeparator = styled.div`
  z-index: 9999;
  position: absolute;
  width: 2px;
  background-color: #fff;
  display: block;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
`;

const HeroSectionWrapper = styled.div`
  position: relative;
`;

const Keyframes = () => {
  useEffect(() => {
    const keyframesRule = '@keyframes float2 { 0% { top: 42%; } 100% { top: 40%; } }';
    const styleElement = document.createElement('style');
    styleElement.appendChild(document.createTextNode(keyframesRule));
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return null;
};



    const settingsSlider = {
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        // infinite: false,
    
        // fade: true ,
        // speed: 500, // ms
        autoplay: true,
        // initialSlide: 2,
        // lazyLoad: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
             slidesToShow: 1,
            }
          }
        ]
    };

    const smoothScroll = function (targetEl, duration) {
        const headerElHeight =  document.querySelector('.menuu').clientHeight;
        let target = document.querySelector(targetEl);
        let targetPosition = target.getBoundingClientRect().top - headerElHeight;
        let startPosition = window.pageYOffset;
        let startTime = null;
      
        const ease = function(t,b,c,d) {
          t /= d / 2;
          if (t < 1) return c / 2 * t * t + b;
          t--;
          return -c / 2 * (t * (t - 2) - 1) + b;
        };
      
        const animation = function(currentTime){
          if (startTime === null) startTime = currentTime;
          const timeElapsed = currentTime - startTime;
          const run = ease(timeElapsed, startPosition, targetPosition, duration);
          window.scrollTo(0,run);
          if (timeElapsed < duration) requestAnimationFrame(animation);
        };
        requestAnimationFrame(animation);
      };

const Home = () => {
    const handleClick = () => {
        smoothScroll('#menuu2', 1000);
      };

        const heroSectionRef1 = useRef("");

      useEffect(() => {
        const heroSection1 = heroSectionRef1.current;
        const heroSeparator1 = heroSection1.querySelector(".hero-separator");
    
        const heroHeight1 = heroSection1.offsetHeight;
        heroSeparator1.style.height = heroHeight1 + "px";
      }, []);
        
    
      const [message, setMessage] = useState('');

      useEffect(() => {
        fetch('https://admin.dacreativesol.com')
          .then(response => response.text())
          .then(data => setMessage(data));
      }, []);
      
      const [users, setUsers] = useState([]);

  useEffect(() => {
    fetch('https://admin.dacreativesol.com/users')
      .then(response => response.json())
      .then(data => setUsers(data));
  }, []);

        return(
		<div>
                  <h1>{message}</h1>
                  <h1>Users</h1>
      <ul>
        {users.map(user => (
          <li key={user.id}>
            <strong>{user.username}</strong> - {user.email}
          </li>
        ))}
      </ul>
             <section class="hero-banner mb-5">
              
             <HeroImage className="hero-image">
             <img src={Banner2} class="img-fluid" alt="hero image" />
      </HeroImage>

                <Keyframes />
      <HeroText className="hero-text hero-text-banner">
      Tarni  Shed
      </HeroText>
                <div class="hero-scroll text-center w-100 menuu">
                    <a class="js-scroll" onClick={handleClick} >
                        <span class="Scroll-text d-block mb-3">
                            Scroll down
                        </span>
                        <span class="scroll-icon">
                            <img class="img-fluid" alt="scroll down icon" src={Banner4} />
                        </span>
                    </a>
                </div>
            </section>
            <section class="py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h2 class="homepage-main-text" id="menuu2">
                                Our workflow and the The way we work is of the <span>greatest</span>
                                importance for our team
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
            <HeroSectionWrapper ref={heroSectionRef1} className="hero-timeline my-5">
               
                <div class="container">
                    <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                        <div class="col-md-5 col-12 order-md-1 order-2 py-md-0 py-2">
                            <div class="timeline-image-wrapper">
                                <img class="img-fluid" src={Service1} alt="timeline hero 01" />
                            </div>
                        </div>
                        <div class="col-md-5 col-12 order-md-2 order-1  py-md-0 py-2">
                            <div class="timeline-text-wrapper py-md-5 py-2">
                                <h3>
                                    He who is not afraid of.
                                </h3>
                                <p>
                                    At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                                    ptatum
                                    deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                    sunt
                                    in culpa qui officia
                                    deserunt mollitia animi.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                        <div class="col-md-5 col-12 order-md-1 order-1">
                            <div class="timeline-text-wrapper py-md-5 py-2">
                                <h3>
                                    He who is not afraid of.
                                </h3>
                                <p>
                                    At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                                    ptatum
                                    deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                    sunt
                                    in culpa qui officia
                                    deserunt mollitia animi.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-5 col-12 order-md-2 order-2">
                            <div class="timeline-image-wrapper">
                                <img class="img-fluid" src={Service2} alt="timeline hero 02" />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                        <div class="col-md-5 col-12 order-md-1 order-2 py-md-0 py-2">
                            <div class="timeline-image-wrapper">
                                <img class="img-fluid" src={Service3} alt="timeline hero 03" />
                            </div>
                        </div>
                        <div class="col-md-5 col-12 order-md-2 order-1  py-md-0 py-2">
                            <div class="timeline-text-wrapper py-md-5 py-2">
                                <h3>
                                    He who is not afraid of.
                                </h3>
                                <p>
                                    At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                                    ptatum
                                    deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                    sunt
                                    in culpa qui officia
                                    deserunt mollitia animi.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                        <div class="col-md-5 col-12 order-md-1 order-1">
                            <div class="timeline-text-wrapper py-md-5 py-2">
                                <h3>
                                    He who is not afraid of.
                                </h3>
                                <p>
                                    At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                                    ptatum
                                    deleniti atque corrupti
                                    quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                                    sunt
                                    in culpa qui officia
                                    deserunt mollitia animi.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-5 col-12 order-md-2 order-2">
                            <div class="timeline-image-wrapper">
                                <img class="img-fluid" src={Service4} alt="timeline hero 04" />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-2 py-md-0 py-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service1} alt="timeline hero 01" />
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-1  py-md-0 py-2">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-around align-items-center my-5 my-md-0 py-md-0 py-4">
                <div class="col-md-5 col-12 order-md-1 order-1">
                    <div class="timeline-text-wrapper py-md-5 py-2">
                        <h3>
                            He who is not afraid of.
                        </h3>
                        <p>
                            At vero eos et accusamus et iusto odio digs imos ducimus qui blanditiis praesentium vlu
                            ptatum
                            deleniti atque corrupti
                            quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
                            sunt
                            in culpa qui officia
                            deserunt mollitia animi.
                        </p>
                    </div>
                </div>
                <div class="col-md-5 col-12 order-md-2 order-2">
                    <div class="timeline-image-wrapper">
                        <img class="img-fluid" src={Service2} alt="timeline hero 02" />
                    </div>
                </div>
            </div>
          
                </div>
                <HeroSeparator heroSectionRef={heroSectionRef1} className="hero-separator d-none d-md-block" />

</HeroSectionWrapper>
            <section class="py-5 characters">
                <div class="container">
                    <div class="w-100">
                        <div class="timeline-text-wrapper py-md-5 py-2 text-center text-md-left">
                            <h3>
                                gallery of the character
                            </h3>
                            <p>
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                                deleniti atque.
                            </p>
                        </div>

                    </div>
                    <div class="w-100 d-flex flex-wrap justify-content-center">
                        <a href="/#" target="_blank" class="character-wrap d-flex justify-content-center align-items-center">
                            <img class="img-fluid" src={Character1} alt="character-01" />
                        </a>
                        <a href="/#" target="_blank" class="character-wrap d-flex justify-content-center align-items-center">
                            <img class="img-fluid" src={Character2} alt="character-02" />
                        </a>
                        <a href="/#" target="_blank" class="character-wrap d-flex justify-content-center align-items-center">
                            <img class="img-fluid" src={Character3} alt="character-03" />
                        </a>
                        <a href="/#" target="_blank" class="character-wrap d-flex justify-content-center align-items-center">
                            <img class="img-fluid" src={Character4} alt="character-04" />
                        </a>
                        <a href="/#" target="_blank" class="character-wrap d-flex justify-content-center align-items-center">
                            <img class="img-fluid" src={Character5} alt="character-05" />
                        </a>
                    </div>
                </div>	
            </section>
            <section class="py-5 character-features">
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-md-6 col-12">
                            <div id="main" class="gfx-main">
                                <div class="container">
                                    <div class="gfx-faq accordion" id="gfxtab">
                                        <div class="card">
                                            <div class="card-header" id="gfxtabhead1">
                                                <a href="/#" class="btn btn-header-link" data-toggle="collapse"
                                                    data-target="#gfxtab1" aria-expanded="true" aria-controls="gfxtab1">
                                                    How many can we mint per wallet?
                                                </a>
                                            </div>

                                            <div id="gfxtab1" class="collapse show" aria-labelledby="gfxtabhead1"
                                                data-parent="#gfxtab">
                                                <div class="card-body">
                                                    At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                                    praesentium voluptatum deleniti atque corrupti
                                                    quos dolores et quas molestias excepturi sint occaecati.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="gfxtabhead2">
                                                <a href="/#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                    data-target="#gfxtab2" aria-expanded="true" aria-controls="gfxtab2">
                                                    How can I get my tarnished warrior?
                                                </a>
                                            </div>

                                            <div id="gfxtab2" class="collapse" aria-labelledby="gfxtabhead2"
                                                data-parent="#gfxtab">
                                                <div class="card-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                    richardson ad squid.
                                                    3 wolf
                                                    moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                    nesciunt laborum
                                                    eiusmod.
                                                    Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                    coffee nulla
                                                    assumenda
                                                    shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                    cred nesciunt
                                                    sapiente ea
                                                    proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                    farm-to-table, raw
                                                    denim
                                                    aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                    sustainable VHS.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="gfxtabhead3">
                                                <a href="/#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                    data-target="#gfxtab3" aria-expanded="true" aria-controls="gfxtab3">
                                                    How many can we mint per wallet?
                                                </a>
                                            </div>

                                            <div id="gfxtab3" class="collapse" aria-labelledby="gfxtabhead3"
                                                data-parent="#gfxtab">
                                                <div class="card-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                    richardson ad squid.
                                                    3 wolf
                                                    moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                    nesciunt laborum
                                                    eiusmod.
                                                    Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                    coffee nulla
                                                    assumenda
                                                    shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                    cred nesciunt
                                                    sapiente ea
                                                    proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                    farm-to-table, raw
                                                    denim
                                                    aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                    sustainable VHS.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="gfxtabhead3">
                                                <a href="/#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                    data-target="#gfxtab4" aria-expanded="true" aria-controls="gfxtab3">
                                                    What is tarnished warriors?
                                                </a>
                                            </div>

                                            <div id="gfxtab4" class="collapse" aria-labelledby="gfxtabhead3"
                                                data-parent="#gfxtab">
                                                <div class="card-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                    richardson ad squid.
                                                    3 wolf
                                                    moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                    nesciunt laborum
                                                    eiusmod.
                                                    Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                    coffee nulla
                                                    assumenda
                                                    shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                    cred nesciunt
                                                    sapiente ea
                                                    proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                    farm-to-table, raw
                                                    denim
                                                    aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                    sustainable VHS.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card">
                                            <div class="card-header" id="gfxtabhead3">
                                                <a href="/#" class="btn btn-header-link collapsed" data-toggle="collapse"
                                                    data-target="#gfxtab5" aria-expanded="true" aria-controls="gfxtab3">
                                                    HOW MUCH FOR TARNISHED?
                                                </a>
                                            </div>

                                            <div id="gfxtab5" class="collapse" aria-labelledby="gfxtabhead3"
                                                data-parent="#gfxtab">
                                                <div class="card-body">
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
                                                    richardson ad squid.
                                                    3 wolf
                                                    moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa
                                                    nesciunt laborum
                                                    eiusmod.
                                                    Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin
                                                    coffee nulla
                                                    assumenda
                                                    shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson
                                                    cred nesciunt
                                                    sapiente ea
                                                    proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer
                                                    farm-to-table, raw
                                                    denim
                                                    aesthetic synth nesciunt you probably haven't heard of them accusamus labore
                                                    sustainable VHS.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5 col-12 text-center">
                            <img class="img-fluid character-features-img" src={features1}
                                alt="charcter features" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-5 gfx-team">
      <div className="container">
        <div className="row">
          <div className="col-12 text-md-right timeline-text-wrapper text-center">
            <h3>our team of experts for crating fun</h3>
          </div>
        </div>
        <div className="row">
          <div className="slide-center1 w-100">
            <Slider {...settingsSlider}>
              <div className="slide-itemss">
                <div className="team-wrapper">
                  <a href="#" target="_blank">
                    <img className="img-fluid" alt="team member 01" src={team1} />
                    <span className="team-name text-center d-block mt-3">Goodwyn The Golden</span>
                    <span className="d-block w-100 team-depart text-center">Animator</span>
                  </a>
                </div>
              </div>
              <div className="slide-itemss">
                <div className="team-wrapper">
                  <a href="#" target="_blank">
                    <img className="img-fluid" alt="team member 02" src={team2} />
                    <span className="team-name text-center d-block mt-3">Marica the Fairy</span>
                    <span className="d-block w-100  team-depart text-center">Developer</span>
                  </a>
                </div>
              </div>
              <div className="slide-itemss">
                <div className="team-wrapper">
                  <a href="#" target="_blank">
                    <img className="img-fluid" alt="team member 03" src={team3} />
                    <span className="team-name text-center d-block mt-3">Rykard the Master</span>
                    <span className="d-block w-100 team-depart text-center">Designer</span>
                  </a>
                </div>
              </div>
              <div className="slide-itemss">
                <div className="team-wrapper">
                  <a href="#" target="_blank">
                    <img className="img-fluid" alt="team member 01" src={team2} />
                    <span className="team-name text-center d-block mt-3">Goodwyn The Golden</span>
                    <span className="d-block w-100 team-depart text-center">Animator</span>
                  </a>
                </div>
              </div>
              <div className="slide-itemss">
                <div className="team-wrapper">
                  <a href="#" target="_blank">
                    <img className="img-fluid" alt="team member 02" src={team1} />
                    <span className="team-name text-center d-block mt-3">Marica the Fairy</span>
                    <span className="d-block w-100  team-depart text-center">Developer</span>
                  </a>
                </div>
              </div>
              <div className="slide-itemss">
                <div className="team-wrapper">
                  <a href="#" target="_blank">
                    <img className="img-fluid" alt="team member 03" src={team2} />
                    <span className="team-name text-center d-block mt-3">Rykard the Master</span>
                    <span className="d-block w-100 team-depart text-center">Designer</span>
                  </a>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
        </div>
        )

    }
    
    export default Home;